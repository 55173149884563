import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, input, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileNameGeneratorService } from '@app/workflow/components/job-configuration/job-input-form/file-name-generator/file-name-generator.service';
import { positiveIntegerRangeValidator } from '@app/workflow/components/job-configuration/job-input-form/file-name-generator/shift-number.validator';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DateFormatSelectorConfig } from '@shared/formly/date-time-format-selector/date-format-selector.component';
import { map, tap } from 'rxjs/operators';

interface ComboBoxItem {
    value: string;
    viewValue: string;
}

export enum FileNameConfiguration {
    TaskName = 'TaskName',
    TaskCategory = 'TaskCategory',
    DayType = 'DayType',
    ShiftType = 'ShiftType',
    ShiftNumber = 'ShiftNumber',
    IsDateSelected = 'IsDateSelected',
    DateFormat = 'DateFormat',
    FileExtensionEnabled = 'FileExtensionEnabled',
    FileExtension = 'FileExtension'
}

@Component({
    selector: 'sphere-file-name-generator',
    templateUrl: './file-name-generator.component.html',
    styleUrls: ['./file-name-generator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FileNameGeneratorComponent implements OnInit {
    @Input() fileNameInput: string;
    @Input() name: string;
    @Input() category: string;
    isFileNameMatched = input<boolean>(true);

    private readonly FILE_EXTENSION_PATTERN = /^\.[\w\d]+$/;
    public FILE_NAME_FORM = new FormGroup({
        [FileNameConfiguration.TaskName]: new FormControl<boolean>(false),
        [FileNameConfiguration.TaskCategory]: new FormControl<boolean>(false),
        [FileNameConfiguration.DayType]: new FormControl<string>('D'),
        [FileNameConfiguration.ShiftType]: new FormControl<string>('same'),
        [FileNameConfiguration.ShiftNumber]: new FormControl<number>(1, positiveIntegerRangeValidator()),
        [FileNameConfiguration.IsDateSelected]: new FormControl<boolean>(false),
        [FileNameConfiguration.FileExtensionEnabled]: new FormControl<boolean>(false),
        [FileNameConfiguration.FileExtension]: new FormControl<string>('', Validators.pattern(this.FILE_EXTENSION_PATTERN))
    });

    @Output() fileNameChange = new EventEmitter<string>();
    @Output() isFileNameFormValid = this.FILE_NAME_FORM.statusChanges.pipe(map(status => status === 'VALID'));

    public readonly DAY_TYPE_OPTIONS: ComboBoxItem[] = [
        { value: 'D', viewValue: 'Day' },
        { value: 'B', viewValue: 'Business Day' }
    ];

    public readonly SHIFT_TYPE_OPTIONS: ComboBoxItem[] = [
        { value: 'same', viewValue: 'Same day' },
        { value: '+', viewValue: 'Days after' },
        { value: '-', viewValue: 'Days before' }
    ];

    public get dayTypeControl() {
        return this.FILE_NAME_FORM.get(FileNameConfiguration.DayType);
    }
    public get dayTypeViewValue(): string {
        return this.DAY_TYPE_OPTIONS.find(dayType => dayType.value === this.dayTypeControl.value)?.viewValue;
    }

    public FORMLY_FIELD_CONFIG: FormlyFieldConfig[] = [];

    public isShiftNumberVisible = false;
    public fileNamePreview = '';
    public isDateSelectedControl = this.FILE_NAME_FORM.get(FileNameConfiguration.IsDateSelected);
    public shiftNumberControl = this.FILE_NAME_FORM.get(FileNameConfiguration.ShiftNumber);

    public isFileExtensionEnabledControl = this.FILE_NAME_FORM.controls[FileNameConfiguration.FileExtensionEnabled];
    public fileExtensionControl = this.FILE_NAME_FORM.controls[FileNameConfiguration.FileExtension];

    private readonly destroyRef = inject(DestroyRef);

    constructor(private fileNameGeneratorService: FileNameGeneratorService) {}

    ngOnInit() {
        this.initForm();
        this.formValueChanges();
    }

    generateOutputFileName(): string {
        const taskName = this.FILE_NAME_FORM.get(FileNameConfiguration.TaskName).value ? 'taskName' : '',
            taskCategory = this.FILE_NAME_FORM.get(FileNameConfiguration.TaskCategory).value ? 'taskCategory' : '',
            dayType = this.FILE_NAME_FORM.get(FileNameConfiguration.DayType).value,
            shiftType = this.FILE_NAME_FORM.get(FileNameConfiguration.ShiftType).value,
            shiftNumber = this.FILE_NAME_FORM.get(FileNameConfiguration.ShiftNumber).value,
            isDateSelected = this.FILE_NAME_FORM.get(FileNameConfiguration.IsDateSelected).value,
            dateFormat = this.FILE_NAME_FORM.get(FileNameConfiguration.DateFormat)?.value,
            fileExtension = this.isFileExtensionEnabledControl.value ? this.fileExtensionControl.value : undefined;

        return this.fileNameGeneratorService.generateOutputFileName({
            taskName,
            taskCategory,
            dateFormat,
            dayType,
            shiftType,
            shiftNumber,
            isDateSelected,
            fileExtension
        });
    }

    generateFileNamePreview(useDatePlaceholder = true): string {
        const hasName = this.FILE_NAME_FORM.get(FileNameConfiguration.TaskName).value,
            hasCategory = this.FILE_NAME_FORM.get(FileNameConfiguration.TaskCategory).value,
            hasDate = this.isDateSelectedControl.value,
            dateFormat = this.FILE_NAME_FORM.get(FileNameConfiguration.DateFormat)?.value,
            fileExtension = this.isFileExtensionEnabledControl.value ? this.fileExtensionControl.value : undefined;
        return this.fileNameGeneratorService.generateFileNamePreview({
            hasName,
            hasCategory,
            hasDate,
            dateFormat,
            name: this.name,
            category: this.category,
            fileExtension,
            useDatePlaceholder
        });
    }

    private formValueChanges(): void {
        this.FILE_NAME_FORM.valueChanges
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => {
                    const output = this.generateOutputFileName();
                    this.fileNamePreview = this.generateFileNamePreview();
                    this.fileNameChange.emit(output);
                })
            )
            .subscribe();

        this.FILE_NAME_FORM.get(FileNameConfiguration.ShiftNumber)
            .valueChanges.pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(value => {
                    this.setShiftNumberValidator();
                })
            )
            .subscribe();

        this.FILE_NAME_FORM.get(FileNameConfiguration.ShiftType)
            .valueChanges.pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(value => {
                    this.setShiftNumberVisible(value);
                    this.setShiftNumberValidator();
                })
            )
            .subscribe();

        this.FILE_NAME_FORM.controls[FileNameConfiguration.FileExtensionEnabled].valueChanges
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(enabled => {
                    if (enabled) {
                        this.fileExtensionControl.setValidators(Validators.pattern(this.FILE_EXTENSION_PATTERN));
                    } else {
                        this.fileExtensionControl.clearValidators();
                    }
                })
            )
            .subscribe();
    }

    private initForm(): void {
        const isWrappedIntoCurlyBraces = /{(.*?)}/;
        let _dateFormat: string = 'dd/MM/yy';

        if (this.fileNameInput && isWrappedIntoCurlyBraces.test(this.fileNameInput)) {
            const { hasTaskName, hasTaskCategory, dateFormat, dayType, shiftType, shiftNumber, hasDateFormat, fileExtension } =
                this.fileNameGeneratorService.parseInput(this.fileNameInput);
            this.FILE_NAME_FORM.patchValue({
                [FileNameConfiguration.TaskName]: hasTaskName,
                [FileNameConfiguration.TaskCategory]: hasTaskCategory,
                [FileNameConfiguration.DayType]: dayType,
                [FileNameConfiguration.ShiftType]: shiftType,
                [FileNameConfiguration.ShiftNumber]: shiftNumber === 0 || isNaN(shiftNumber) ? 1 : shiftNumber,
                [FileNameConfiguration.IsDateSelected]: hasDateFormat,
                [FileNameConfiguration.FileExtensionEnabled]: !!fileExtension,
                [FileNameConfiguration.FileExtension]: fileExtension
            });
            if (dateFormat) {
                _dateFormat = dateFormat;
            }
            this.setShiftNumberVisible(shiftType);
        }

        // set field config
        const fieldConfig: DateFormatSelectorConfig = {
            key: 'DateFormat',
            type: 'date-format',
            className: 'file-name__format',
            defaultValue: _dateFormat,
            props: {
                label: 'Date format',
                includeFreeFormOption: true,
                standardDateFormatOptions: true,
                options: [
                    { displayName: 'Day/Month/Year', value: 'dd/MM/yy' },
                    { displayName: 'Month/Day/Year', value: 'MM/dd/yyyy' },
                    { displayName: 'Year/Month/Day', value: 'yyyy/MM/dd' }
                ],
                skipPreview: true,
                useDotNetPatternValidation: true,
                replaceMap: [{ match: ',', replaceWith: '' }],
                width: 50
            }
        };

        this.FORMLY_FIELD_CONFIG.push(fieldConfig);
    }

    private setShiftNumberVisible(shiftType: string): void {
        this.isShiftNumberVisible = shiftType !== 'same';
    }

    private setShiftNumberValidator(): void {
        if (this.isShiftNumberVisible) {
            this.shiftNumberControl.setValidators(positiveIntegerRangeValidator());
        } else {
            this.shiftNumberControl.clearValidators();
        }
        this.shiftNumberControl.updateValueAndValidity();
    }
}
