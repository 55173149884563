/**
 * T&R Kpler service REST API
 * Trading & Risk Kpler service RESTful Application Programming Interface
 *
 * The version of the OpenAPI document: 2.3
 * Contact: gcb-rt@enverus.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { FlowDTO } from '../model/models';
import { FlowDirection } from '../model/models';
import { FlowStatus } from '../model/models';
import { FlowUnit } from '../model/models';
import { KplerProduct } from '../model/models';
import { SplitOption } from '../model/models';
import { TimePeriod } from '../model/models';
import { YoyOption } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { KplerAPIConfiguration } from '../configuration';
import {
    TradingFlowControllerServiceInterface,
    GetCountriesRequestParams,
    GetFlowsRequestParams,
    GetKplerProductsRequestParams,
    GetKplerProducts1RequestParams,
    GetPortsRequestParams,
    GetProductGradesRequestParams,
    GetProductGrades1RequestParams,
    GetRegionsRequestParams,
    GetTopFlowsRequestParams
} from './tradingFlowController.serviceInterface';

@Injectable({
    providedIn: 'root'
})
export class TradingFlowControllerService implements TradingFlowControllerServiceInterface {
    protected basePath = 'http://localhost/api/kpler';
    public defaultHeaders = new HttpHeaders();
    public configuration = new KplerAPIConfiguration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: KplerAPIConfiguration
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * Get available commodities
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommodities(
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<string>>;
    public getCommodities(
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<string>>>;
    public getCommodities(
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<string>>>;
    public getCommodities(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/commodities`, {
            context: localVarHttpContext,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get available countries
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCountries(
        requestParameters: GetCountriesRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<string>>;
    public getCountries(
        requestParameters: GetCountriesRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<string>>>;
    public getCountries(
        requestParameters: GetCountriesRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<string>>>;
    public getCountries(
        requestParameters: GetCountriesRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        const ancestorType = requestParameters.ancestorType;
        const ancestorName = requestParameters.ancestorName;

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (ancestorType !== undefined && ancestorType !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ancestorType, 'ancestorType');
        }
        if (ancestorName !== undefined && ancestorName !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ancestorName, 'ancestorName');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/countries`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get aggregated flows for a point of interest
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlows(
        requestParameters: GetFlowsRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<FlowDTO>>;
    public getFlows(
        requestParameters: GetFlowsRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<FlowDTO>>>;
    public getFlows(
        requestParameters: GetFlowsRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<FlowDTO>>>;
    public getFlows(
        requestParameters: GetFlowsRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        const flowDirection = requestParameters.flowDirection;
        if (flowDirection === null || flowDirection === undefined) {
            throw new Error('Required parameter flowDirection was null or undefined when calling getFlows.');
        }
        const granularity = requestParameters.granularity;
        if (granularity === null || granularity === undefined) {
            throw new Error('Required parameter granularity was null or undefined when calling getFlows.');
        }
        const splits = requestParameters.splits;
        if (splits === null || splits === undefined) {
            throw new Error('Required parameter splits was null or undefined when calling getFlows.');
        }
        const startDate = requestParameters.startDate;
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getFlows.');
        }
        const endDate = requestParameters.endDate;
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getFlows.');
        }
        const products = requestParameters.products;
        if (products === null || products === undefined) {
            throw new Error('Required parameter products was null or undefined when calling getFlows.');
        }
        const unit = requestParameters.unit;
        if (unit === null || unit === undefined) {
            throw new Error('Required parameter unit was null or undefined when calling getFlows.');
        }
        const flowStatus = requestParameters.flowStatus;
        if (flowStatus === null || flowStatus === undefined) {
            throw new Error('Required parameter flowStatus was null or undefined when calling getFlows.');
        }
        const withIntraCountry = requestParameters.withIntraCountry;
        if (withIntraCountry === null || withIntraCountry === undefined) {
            throw new Error('Required parameter withIntraCountry was null or undefined when calling getFlows.');
        }
        const isYoY = requestParameters.isYoY;
        if (isYoY === null || isYoY === undefined) {
            throw new Error('Required parameter isYoY was null or undefined when calling getFlows.');
        }
        const yoyOption = requestParameters.yoyOption;
        if (yoyOption === null || yoyOption === undefined) {
            throw new Error('Required parameter yoyOption was null or undefined when calling getFlows.');
        }
        const precision = requestParameters.precision;
        if (precision === null || precision === undefined) {
            throw new Error('Required parameter precision was null or undefined when calling getFlows.');
        }
        const fromZone = requestParameters.fromZone;
        const toZone = requestParameters.toZone;
        const grades = requestParameters.grades;

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (flowDirection !== undefined && flowDirection !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>flowDirection, 'flowDirection');
        }
        if (granularity !== undefined && granularity !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>granularity, 'granularity');
        }
        if (splits) {
            splits.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'splits');
            });
        }
        if (fromZone !== undefined && fromZone !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fromZone, 'fromZone');
        }
        if (toZone !== undefined && toZone !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>toZone, 'toZone');
        }
        if (startDate !== undefined && startDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'endDate');
        }
        if (products) {
            products.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'products');
            });
        }
        if (grades) {
            grades.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'grades');
            });
        }
        if (unit !== undefined && unit !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>unit, 'unit');
        }
        if (flowStatus !== undefined && flowStatus !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>flowStatus, 'flowStatus');
        }
        if (withIntraCountry !== undefined && withIntraCountry !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>withIntraCountry, 'withIntraCountry');
        }
        if (isYoY !== undefined && isYoY !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isYoY, 'isYoY');
        }
        if (yoyOption !== undefined && yoyOption !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>yoyOption, 'yoyOption');
        }
        if (precision !== undefined && precision !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>precision, 'precision');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FlowDTO>>(`${this.configuration.basePath}/flows`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get list of available KplerProducts. Lower granularity filter provided is applied
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKplerProducts(
        requestParameters: GetKplerProductsRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<KplerProduct>>;
    public getKplerProducts(
        requestParameters: GetKplerProductsRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<KplerProduct>>>;
    public getKplerProducts(
        requestParameters: GetKplerProductsRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<KplerProduct>>>;
    public getKplerProducts(
        requestParameters: GetKplerProductsRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        const commodityGroup = requestParameters.commodityGroup;
        const commodity = requestParameters.commodity;
        const family = requestParameters.family;

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (commodityGroup !== undefined && commodityGroup !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commodityGroup, 'commodityGroup');
        }
        if (commodity !== undefined && commodity !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commodity, 'commodity');
        }
        if (family !== undefined && family !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>family, 'family');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<KplerProduct>>(`${this.configuration.basePath}/kplerProducts`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get list of available KplerProducts. Lower granularity filter provided is applied
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKplerProducts1(
        requestParameters: GetKplerProducts1RequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<KplerProduct>>;
    public getKplerProducts1(
        requestParameters: GetKplerProducts1RequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<KplerProduct>>>;
    public getKplerProducts1(
        requestParameters: GetKplerProducts1RequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<KplerProduct>>>;
    public getKplerProducts1(
        requestParameters: GetKplerProducts1RequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        const commodityGroup = requestParameters.commodityGroup;
        const commodity = requestParameters.commodity;
        const family = requestParameters.family;

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (commodityGroup !== undefined && commodityGroup !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commodityGroup, 'commodityGroup');
        }
        if (commodity !== undefined && commodity !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commodity, 'commodity');
        }
        if (family !== undefined && family !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>family, 'family');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<KplerProduct>>(`${this.configuration.basePath}/kpler-products`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get available ports
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPorts(
        requestParameters: GetPortsRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<string>>;
    public getPorts(
        requestParameters: GetPortsRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<string>>>;
    public getPorts(
        requestParameters: GetPortsRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<string>>>;
    public getPorts(
        requestParameters: GetPortsRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        const ancestorType = requestParameters.ancestorType;
        const ancestorName = requestParameters.ancestorName;

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (ancestorType !== undefined && ancestorType !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ancestorType, 'ancestorType');
        }
        if (ancestorName !== undefined && ancestorName !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ancestorName, 'ancestorName');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/ports`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get String list of available product Grades
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductGrades(
        requestParameters: GetProductGradesRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<string>>;
    public getProductGrades(
        requestParameters: GetProductGradesRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<string>>>;
    public getProductGrades(
        requestParameters: GetProductGradesRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<string>>>;
    public getProductGrades(
        requestParameters: GetProductGradesRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        const ancestorProductNames = requestParameters.ancestorProductNames;

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (ancestorProductNames !== undefined && ancestorProductNames !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ancestorProductNames, 'ancestorProductNames');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/productGrades`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get String list of available product Grades
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductGrades1(
        requestParameters: GetProductGrades1RequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<string>>;
    public getProductGrades1(
        requestParameters: GetProductGrades1RequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<string>>>;
    public getProductGrades1(
        requestParameters: GetProductGrades1RequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<string>>>;
    public getProductGrades1(
        requestParameters: GetProductGrades1RequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        const ancestorProductNames = requestParameters.ancestorProductNames;

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (ancestorProductNames !== undefined && ancestorProductNames !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ancestorProductNames, 'ancestorProductNames');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/product-grades`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get available regions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegions(
        requestParameters: GetRegionsRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<string>>;
    public getRegions(
        requestParameters: GetRegionsRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<string>>>;
    public getRegions(
        requestParameters: GetRegionsRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<string>>>;
    public getRegions(
        requestParameters: GetRegionsRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        const ancestorType = requestParameters.ancestorType;
        const ancestorName = requestParameters.ancestorName;

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (ancestorType !== undefined && ancestorType !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ancestorType, 'ancestorType');
        }
        if (ancestorName !== undefined && ancestorName !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ancestorName, 'ancestorName');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/regions`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get available splits
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSplits(
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<SplitOption>>;
    public getSplits(
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<SplitOption>>>;
    public getSplits(
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<SplitOption>>>;
    public getSplits(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SplitOption>>(`${this.configuration.basePath}/splits`, {
            context: localVarHttpContext,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get aggregated flows for a point of interest
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTopFlows(
        requestParameters: GetTopFlowsRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<FlowDTO>>;
    public getTopFlows(
        requestParameters: GetTopFlowsRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<FlowDTO>>>;
    public getTopFlows(
        requestParameters: GetTopFlowsRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<FlowDTO>>>;
    public getTopFlows(
        requestParameters: GetTopFlowsRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        const flowDirection = requestParameters.flowDirection;
        if (flowDirection === null || flowDirection === undefined) {
            throw new Error('Required parameter flowDirection was null or undefined when calling getTopFlows.');
        }
        const granularity = requestParameters.granularity;
        if (granularity === null || granularity === undefined) {
            throw new Error('Required parameter granularity was null or undefined when calling getTopFlows.');
        }
        const splits = requestParameters.splits;
        if (splits === null || splits === undefined) {
            throw new Error('Required parameter splits was null or undefined when calling getTopFlows.');
        }
        const startDate = requestParameters.startDate;
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getTopFlows.');
        }
        const endDate = requestParameters.endDate;
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getTopFlows.');
        }
        const unit = requestParameters.unit;
        if (unit === null || unit === undefined) {
            throw new Error('Required parameter unit was null or undefined when calling getTopFlows.');
        }
        const flowStatus = requestParameters.flowStatus;
        if (flowStatus === null || flowStatus === undefined) {
            throw new Error('Required parameter flowStatus was null or undefined when calling getTopFlows.');
        }
        const withIntraCountry = requestParameters.withIntraCountry;
        if (withIntraCountry === null || withIntraCountry === undefined) {
            throw new Error('Required parameter withIntraCountry was null or undefined when calling getTopFlows.');
        }
        const isYoY = requestParameters.isYoY;
        if (isYoY === null || isYoY === undefined) {
            throw new Error('Required parameter isYoY was null or undefined when calling getTopFlows.');
        }
        const precision = requestParameters.precision;
        if (precision === null || precision === undefined) {
            throw new Error('Required parameter precision was null or undefined when calling getTopFlows.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getTopFlows.');
        }
        const fromZone = requestParameters.fromZone;
        const toZone = requestParameters.toZone;
        const products = requestParameters.products;
        const grades = requestParameters.grades;
        const yoyOption = requestParameters.yoyOption;

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (flowDirection !== undefined && flowDirection !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>flowDirection, 'flowDirection');
        }
        if (granularity !== undefined && granularity !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>granularity, 'granularity');
        }
        if (splits) {
            splits.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'splits');
            });
        }
        if (fromZone !== undefined && fromZone !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fromZone, 'fromZone');
        }
        if (toZone !== undefined && toZone !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>toZone, 'toZone');
        }
        if (startDate !== undefined && startDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'endDate');
        }
        if (products) {
            products.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'products');
            });
        }
        if (grades) {
            grades.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'grades');
            });
        }
        if (unit !== undefined && unit !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>unit, 'unit');
        }
        if (flowStatus !== undefined && flowStatus !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>flowStatus, 'flowStatus');
        }
        if (withIntraCountry !== undefined && withIntraCountry !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>withIntraCountry, 'withIntraCountry');
        }
        if (isYoY !== undefined && isYoY !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isYoY, 'isYoY');
        }
        if (yoyOption !== undefined && yoyOption !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>yoyOption, 'yoyOption');
        }
        if (precision !== undefined && precision !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>precision, 'precision');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FlowDTO>>(`${this.configuration.basePath}/top-flows/${encodeURIComponent(String(limit))}`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get available units
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnits(
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<FlowUnit>>;
    public getUnits(
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<FlowUnit>>>;
    public getUnits(
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<FlowUnit>>>;
    public getUnits(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FlowUnit>>(`${this.configuration.basePath}/units`, {
            context: localVarHttpContext,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }
}
